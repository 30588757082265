import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import Box from "@mui/material/Box";
import "./paymentsuccess.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LanguageIcon from "@mui/icons-material/Language";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import Logo from "../../Comman/Logo/Logo";
import api from "../../CommonApi/axios";
import SweetAlert from "react-bootstrap-sweetalert";

import { connect } from "react-redux";
import {
  getCharges,
  setProductList,
  checkout,
  setCheckoutData,
  checkOrderNotes,
  setOrderCostData,
  getOrderCost,
  setDeliveryError,
  setLanguage,
  getBranchDetails,
  setBranchPrimaryLanguage,
  getMenuList,
  setBranchId,
  setType,
  setBranchName,
} from "../../Store";
import Swal from "sweetalert2";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CommonHeader from "../../Comman/CommonHeader.js/CommonHeader";



function PaymentSuccessDineIn(props) {
  const {
    setProductList,
    productList,
    menuList,
    checkout,
    getCharges,
    charges,
    setCheckoutData,
    branchId,
    type,
    checkOrderNotes,
    showordernote,
    setOrderCostData,
    getOrderCost,
    deliverycosterror,
    setDeliveryError,
    deliverycost,
    setLanguage,
    language,
    getBranchDetails,
    branchLanguageData,
    branchPrimaryLanguage,
    setBranchPrimaryLanguage,
    getMenuList,
    branchName,
    setBranchId,
    setType,
    setBranchName,
    branchdeliverysettingsdataData,
    customRes,
    branchDineInTimeData,
    orderData,
  } = props;
  let history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const { poId } = useParams();
  const { tableId } = useParams();

  var pathname = window.location.href;
  var pathnamesplits = pathname
    .replace(/(https?:\/\/)?(www.)?/i, "")
    .split("/");
  var url = pathname.replace(/(https?:\/\/)?(www.)?/i, "");
  var Branch_id = String(pathnamesplits[1])
  var TableNo = String(pathnamesplits[2])
  var unique_code = String(pathnamesplits[5]);
  

  // console.log("orderID" ,TableNo , unique_code ,Branch_id) 

  const Payment = sessionStorage.getItem("Payment");

  const [abcd, setabcd] = useState(Payment ? Payment : false);
  const [Button1, setButton1] = useState(false);

  const myString = unique_code;
  const extractedString = myString?.split('#')[0];

  // console.log(extractedString);

  // useEffect(() => {
  //   handleSubmit();
  // }, []);

  useEffect(() => {
    if(extractedString && Payment === null){
      handleSubmit();
    }
  }, [extractedString && Payment === null]);

  let storedItemPrintJSON = localStorage.getItem('itemPrint');

  console.log("storedItemPrintJSON" ,storedItemPrintJSON)


  function handleSubmit() {
    let item = {
      unique_code: extractedString,
      table_number : TableNo,
      status: "success",
      branch_id : Branch_id
    };
    api
      .patch(`/client/order/updateDineInOrderStatus`, item)
      .then((res) => {

        setabcd(true);
        sessionStorage.setItem("Payment", true);
      //   console.log("success", res.data);
          if(storedItemPrintJSON !== null){
            let itemPrint = {
              order_id : storedItemPrintJSON
            }

            console.log("itemPrint" ,itemPrint)
        
            api.post("/client/order/print-order-summary" , itemPrint).then((res)=>{
                // console.log("res" ,res);
                localStorage.removeItem('itemPrint')
            }).catch((err)=>{
        
            })
          }
      })
      .catch((err) => {
        if (err.response) {
          // console.log("err.response.message", err.response.data.error.message);
          setButton1(true);
          toast.error(err.response.data.error.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  }

  const downloadFile = (value) => {
    api
      .get(`/client/order/downloadOrderInvoiceForDineIn/${value}`)
      .then((res) => {
        const tableData = res.data.data;
        window.open(`${tableData}`);
        // console.log("downloadOrderInvoice", tableData);
        // window.location.href = `${tableData}`;
      })
      .catch((error) => {
        toast.error(error?.message?.data?.error?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const location = useLocation();

  useEffect(()=>{
    window.history.pushState(null, null, location.href);
    window.onpopstate = function(event) {
    history.go(1);
  };
  },[])



  useEffect(()=>{
    
  },[storedItemPrintJSON !== null])



  return (
    <React.Fragment>
      {/* <Navbar
        style={{height :75}}
        className="shadow-lg p-2 bg-body"
        collapseOnSelect
        expand="lg"
        variant="dark"
      >
        <Container fluid>
          <Navbar.Brand>
            <Logo />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"></Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <div className="my-2"></div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}

      <CommonHeader/>
      <div
        style={{
          backgroundColor: "#F0EEED",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container>
          {abcd === false ? (
            <Row className="justify-content-sm-center">
              <div
                className="text-center"
                style={{ width: "600px", backgroundColor: "#F0EEED" }}
              >
                <Container>
                  <Card style={{border : 0 , borderRadius : 0}}>
                    <Card.Body>
                      <div>
                        <img
                          style={{ height: "100px", width: "100px" }}
                          src="/assets/media/my/loadnig.gif"
                          className=""
                          alt="loader"
                        />
                      </div>

                      <Row>
                        <Col lg={2}></Col>
                        <Col lg={8}>
                          <Card.Title className="header_success mt-3">
                            Payment Processing
                          </Card.Title>
                        </Col>
                        <Col lg={2}></Col>
                      </Row>

                      <Row>
                        <Col lg={2}></Col>
                        <Col lg={8}>
                          <Card.Title className="payment_text_info mt-2">
                            Please wait , we are confirming your payment and
                            please donot press back & close window.
                          </Card.Title>
                        </Col>
                        <Col lg={2}></Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Container>
              </div>
            </Row>
          ) : (
            <Row className="justify-content-sm-center">
              <div
                className="text-center"
                style={{ width: "580px", backgroundColor: "#F0EEED" }}
              >
                <Container>
                  <Card>
                    <Card.Body>
                      <Card.Title>
                        <div lassName="mt-3 mb-4">
                          <img
                            style={{ height: "80px" }}
                            src="/assets/media/my/check.png"
                            alt="success_png"
                          />
                        </div>
                      </Card.Title>

                      <Row>
                        <Col lg={2}></Col>
                        <Col lg={8}>
                          <Card.Title className="header_success">
                          Thank you for your visit
                          </Card.Title>
                        </Col>
                        <Col lg={2}></Col>
                      </Row>
                      <Row>
                        <Col lg={2}></Col>
                        <Col lg={8}>
                          <Card.Title className="payment_order_id mt-2">
                            <strong>
                            Hope you had a great time.
                            </strong>
                          </Card.Title>
                        </Col>
                        <Col lg={2}></Col>
                      </Row>
                      <Row>
                        <Col lg={2}></Col>
                        <Col lg={8}>
                          <Card.Title className="payment_text_info mt-1">
                          To dine in again, please scan the QR Code. We look forward to serving you once more!
                          </Card.Title>
                        </Col>
                        <Col lg={2}></Col>
                      </Row>

                      <Row>
                        <Col
                          lg={12}
                          xs={12}
                          md={12}
                          className="text-center"
                        >
                          <Button
                            type="button"
                            style={{
                              backgroundColor:
                                customRes?.button_colour ?? "#dc3545",
                              border: 0,
                            }}
                            className="my-4 custom_hover"
                            // onClick={() => setabcd(!abcd)}
                            onClick={() => {
                              downloadFile(extractedString);
                            }}
                          >
                            <strong
                              style={{
                                color:
                                  customRes?.non_highlighted_text ?? "white",
                              }}
                            >
                              Download Receipt{" "}
                            </strong>
                          </Button>
                        </Col>
                    
                      </Row>
                    </Card.Body>
                  </Card>
                </Container>
              </div>
            </Row>
          )}
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  productList: state.product.productList,
  menuList: state.menu.menuList,
  branchdeliverysettingsdataData: state.branch.branchdeliverysettingsdataData,
  branchDineInTimeData: state.branch.branchDineInTimeData,
  charges: state.order.charges,
  branchId: state.branch.branchId,
  branchName: state.branch.branchName,
  type: state.branch.type,
  showordernote: state.order.showordernote,
  deliverycosterror: state.order.deliverycosterror,
  deliverycost: state.order.deliverycost,
  language: state.branch.language,
  branchPrimaryLanguage: state.branch.branchPrimaryLanguage,
  branchLanguageData: state.branch.branchLanguageData,
  customRes: state.custom.customRes,
  orderData: state.order.orderData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setProductList: (data) => dispatch(setProductList(data)),
    checkout: () => dispatch(checkout()),
    getCharges: () => dispatch(getCharges()),
    setBranchId: (id) => dispatch(setBranchId(id)),
    setBranchName: (name) => dispatch(setBranchName(name)),
    setType: (type) => dispatch(setType(type)),
    setCheckoutData: (data) => dispatch(setCheckoutData(data)),
    checkOrderNotes: () => dispatch(checkOrderNotes()),
    setOrderCostData: (data) => dispatch(setOrderCostData(data)),
    getOrderCost: () => dispatch(getOrderCost()),
    setDeliveryError: (error) => dispatch(setDeliveryError(error)),
    setLanguage: (language) => dispatch(setLanguage(language)),
    getBranchDetails: () => dispatch(getBranchDetails()),
    setBranchPrimaryLanguage: (language) =>
      dispatch(setBranchPrimaryLanguage(language)),
    getMenuList: () => dispatch(getMenuList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccessDineIn);
