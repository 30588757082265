import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import Box from "@mui/material/Box";
import "./paymentsuccess.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LanguageIcon from "@mui/icons-material/Language";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
// import Button from '@mui/material/Button';
import Logo from "../../Comman/Logo/Logo";
import api from "../../CommonApi/axios";
import SweetAlert from "react-bootstrap-sweetalert";

import { connect } from "react-redux";
import {
  getCharges,
  setProductList,
  checkout,
  setCheckoutData,
  checkOrderNotes,
  setOrderCostData,
  getOrderCost,
  setDeliveryError,
  setLanguage,
  getBranchDetails,
  setBranchPrimaryLanguage,
  getMenuList,
  setBranchId,
  setType,
  setBranchName,
} from "../../Store";
import Swal from "sweetalert2";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CommonHeader from "../../Comman/CommonHeader.js/CommonHeader";
import QRCodeOrder from "../QRCODE/QRCodeOrder";

function PaymentSuccess(props) {
  const {
    setProductList,
    productList,
    menuList,
    checkout,
    getCharges,
    charges,
    setCheckoutData,
    branchId,
    type,
    checkOrderNotes,
    showordernote,
    setOrderCostData,
    getOrderCost,
    deliverycosterror,
    setDeliveryError,
    deliverycost,
    setLanguage,
    language,
    getBranchDetails,
    branchLanguageData,
    branchPrimaryLanguage,
    setBranchPrimaryLanguage,
    getMenuList,
    branchName,
    setBranchId,
    setType,
    setBranchName,
    branchdeliverysettingsdataData,
    customRes,
    branchDineInTimeData,
    orderData,
  } = props;
  let history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  var pathname = window.location.href;
  var pathnamesplits = pathname
    .replace(/(https?:\/\/)?(www.)?/i, "")
    .split("/");
  var url = pathname.replace(/(https?:\/\/)?(www.)?/i, "");
  var orderID = pathnamesplits[5];

  const [extractedString, setExtractedString] = useState('');
  let orderForQr = sessionStorage.getItem("orderForQr");


  useEffect(() => {
    const pay_unicID = pathnamesplits[6];
    const myString = pay_unicID;
    const extractedString = myString?.split('#')[0];

    // Update the state with the extracted string
    setExtractedString(extractedString);
  }, [pathnamesplits]);
  const Payment = sessionStorage.getItem("Payment");

  const [abcd, setabcd] = useState(Payment ? Payment : false);

  const location = useLocation();

  useEffect(() => {
    window.history.pushState(null, null, location.href);
    window.onpopstate = function (event) {
      history.go(1);
    };
  }, [])


  useEffect(() => {
    if (orderID && Payment === null) {
      handleSubmit();
    }
  }, [orderID && Payment === null]);

  function handleSubmit() {
    if (orderID) {
      let item = {
        order_id: orderID,
        status: "success",
      };
      api
        .patch(`/client/order/updateOrderStatus`, item)
        .then((res) => {
          // console.log("success", res.data);
          sessionStorage.setItem("Payment", true);
          setabcd(true);
        })
        .catch((err) => {
          if (err.response) {
            // setabcd(true);
            toast.error(err.response.data.error.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
    }
  }

  const downloadFile = (value) => {
    api
      .get(`/client/order/downloadOrderInvoice/${value}`)
      .then((res) => {
        const tableData = res.data.data;
        window.open(`${tableData}`);
        // console.log("downloadOrderInvoice", tableData);
        // window.location.href = `${tableData}`;
      })
      .catch((error) => {
        toast.error(error?.message?.data?.error?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const getCommonButtonStyles = (buttonColour, nonHighlightedText) => ({
    backgroundColor: buttonColour ?? "#dc3545",
    border: 0,
    color: nonHighlightedText ?? "white",
    textTransform: "none",
    width: "100%",
    fontWeight: 600
  });

  const commonButtonStyles = getCommonButtonStyles(customRes?.button_colour, customRes?.non_highlighted_text);

  const card = { border: 0, borderRadius: 0 }
  return (
    <React.Fragment>
      {/* <Navbar
        style={{height :75}}
        className="shadow-lg p-2 bg-body"
        collapseOnSelect
        expand="lg"
        variant="dark"
      >
        <Container fluid>
          <Navbar.Brand>
            <Logo />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"></Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <div className="my-2"></div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
      <CommonHeader />
      <div
        style={{
          backgroundColor: "#F0EEED",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container>
          {abcd == false ? (
            <Row className="justify-content-sm-center">
              <div
                className="text-center"
                style={{ width: "600px" }}
              >
                <Container>
                  <Card style={card}>
                    <Card.Body>
                      <div>
                        <img
                          style={{ height: "100px", width: "100px" }}
                          src="/assets/media/my/loadnig.gif"
                          alt="loader"
                        />
                      </div>
                      <Row className="text-center">
                        <Card.Title className="header_success mt-3">
                          Payment Processing
                        </Card.Title>
                      </Row>

                      <Row>
                        <Col lg={2}></Col>
                        <Col lg={8}>
                          <Card.Title className="payment_text_info mt-2">
                            Please wait , we are confirming your payment and
                            please donot press back & close window.
                          </Card.Title>
                        </Col>
                        <Col lg={2}></Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Container>
              </div>
            </Row>
          ) : (
            <Row className="justify-content-sm-center">
              <div
                className="text-center"
                style={{ width: "580px" }}
              >
                <Container>
                  <Card style={card}>
                    <Card.Body>
                      <Card.Title>
                        <div lassName="mt-3 mb-4">
                          <img
                            style={{ height: "80px" }}
                            src="/assets/media/my/check.png"
                            alt="success_png"
                          />
                        </div>
                      </Card.Title>

                      <Row className="text-center">
                        <Card.Title className="header_success">
                          Your order is confirmed
                        </Card.Title>
                      </Row>
                      <Row className="text-center">
                        <Card.Title className="payment_order_id mt-2">
                          <strong>
                            Your order number : #{extractedString || ""}
                          </strong>
                        </Card.Title>
                      </Row>
                      <Row>
                        <Col lg={2} xs={0} sm={0} md={2}></Col>
                        <Col lg={8} xs={12}>
                          <Card.Title className="payment_text_info mt-1">
                            The order confirmation invoice will be shared on
                            your registered email.
                          </Card.Title>
                        </Col>
                        <Col lg={2} xs={0} sm={0} md={2}></Col>
                      </Row>

                      {orderForQr === "pickup" &&
                        <QRCodeOrder OrderNumber={extractedString} />
                      }
                      <Row className="mx-4">
                        <Col
                          lg={6}
                          xs={12}
                          md={6}
                          className={
                            isMobile == true ? "text-center" : "text-end"
                          }
                        >
                          <Button
                            type="button"
                            style={commonButtonStyles}
                            className="my-4 custom_hover"
                            // onClick={() => setabcd(!abcd)}
                            onClick={() => {
                              downloadFile(orderID);
                            }}
                          >
                            Download Receipt
                          </Button>
                        </Col>
                        <Col
                          lg={6}
                          xs={12}
                          md={6}
                          className={
                            isMobile == true ? "text-center" : "text-start"
                          }
                        >
                          <Button
                            style={{ ...commonButtonStyles }}
                            type="button"
                            className={
                              isMobile == true
                                ? "my-1 custom_hover"
                                : "my-4 custom_hover"
                            }
                            onClick={() => {
                              sessionStorage.removeItem("Payment");
                              history.push(`/`);
                              sessionStorage.removeItem("orderForQr");
                            }
                            }
                          >
                            Back to Home
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Container>
              </div>
            </Row>
          )}
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  productList: state.product.productList,
  menuList: state.menu.menuList,
  branchdeliverysettingsdataData: state.branch.branchdeliverysettingsdataData,
  branchDineInTimeData: state.branch.branchDineInTimeData,
  charges: state.order.charges,
  branchId: state.branch.branchId,
  branchName: state.branch.branchName,
  type: state.branch.type,
  showordernote: state.order.showordernote,
  deliverycosterror: state.order.deliverycosterror,
  deliverycost: state.order.deliverycost,
  language: state.branch.language,
  branchPrimaryLanguage: state.branch.branchPrimaryLanguage,
  branchLanguageData: state.branch.branchLanguageData,
  customRes: state.custom.customRes,
  orderData: state.order.orderData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setProductList: (data) => dispatch(setProductList(data)),
    checkout: () => dispatch(checkout()),
    getCharges: () => dispatch(getCharges()),
    setBranchId: (id) => dispatch(setBranchId(id)),
    setBranchName: (name) => dispatch(setBranchName(name)),
    setType: (type) => dispatch(setType(type)),
    setCheckoutData: (data) => dispatch(setCheckoutData(data)),
    checkOrderNotes: () => dispatch(checkOrderNotes()),
    setOrderCostData: (data) => dispatch(setOrderCostData(data)),
    getOrderCost: () => dispatch(getOrderCost()),
    setDeliveryError: (error) => dispatch(setDeliveryError(error)),
    setLanguage: (language) => dispatch(setLanguage(language)),
    getBranchDetails: () => dispatch(getBranchDetails()),
    setBranchPrimaryLanguage: (language) =>
      dispatch(setBranchPrimaryLanguage(language)),
    getMenuList: () => dispatch(getMenuList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);
