import { fontFamily, height } from "@mui/system";

export const customSelectStyles = () => ({
      control: (provided) => ({
        ...provided,
        borderColor: '#D0D5DD',
        boxShadow: 'none',
        borderRadius : 8,
        height  : 45,
        fontFamily: 'Calibri, sans-serif',
      //   '&:hover': { borderColor: 'darkblue' },
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#e7e8eb' : state.isFocused ? 'lightgray' : 'white',
        color: 'black',
        '&:active': { backgroundColor: 'blue', color: 'white' }
      }),
      singleValue: (provided) => ({
        ...provided,
        color: '#212529',
      }),
      placeholder: (provided) => ({
        ...provided,
        color: 'gray',
      })
    });