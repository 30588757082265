// src/sentryConfig.js
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


Sentry.init({
  dsn: "https://af8ea969354a32e3e6af9ef49a8bfaae@o4508177543200768.ingest.us.sentry.io/4508239169454080", // Use environment variable
  Integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
  tracesSampleRate: 1.0, // Capture 100% of the transactions for tracing
  profilesSampleRate: 1.0, // Capture profiling data at 100%
  debug: true, // Enable debug mode to log info in the console
  beforeSend(event) {
    console.log("Event sent to Sentry:", event); // Log the event
    return event; // Allows you to modify event data before it's sent
  },
});

export default Sentry;
