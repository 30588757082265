import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Form, InputGroup } from 'react-bootstrap';
import api from '../../../../CommonApi/axios';
import { showSuccessNotification } from '../../../CommonComponet/Swal/Swal';
import useError from '../../../CommonComponet/Error/useError';
import { LocationRegular, CallRegular, MailRegular } from '@fluentui/react-icons';
import Select from "react-select";
import Button from '@mui/material/Button';


import "./DeliveryDetailsModel.css"
import { customSelectStyles } from './CoomonSelectCss';
import { Errormessage } from '../../../CommonComponet/Error/Errormessage';
import { validateEmail } from '../../../CommonComponet/Email/Email';
import moment from 'moment';

function PickupDetailsModel({ showPickup, closePickup }) {


    let sessionBranchID = sessionStorage.getItem("sessionBranchID");
    let pickupInfo = JSON.parse(sessionStorage.getItem("pickupInfo")) || null;

    const [pickup_order_time, setpickup_order_time] = useState(null);
    const [pickup_order_date, setpickup_order_date] = useState(null);
    const [fullName, setFullName] = useState("");
    const [phone, setPhone] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [error, setError] = useState(null);
    const [PickupTimeList, setPickupTimeList] = useState([]);
    const [PickupDays, setPickupDays] = useState([]);
    const [IndexForPickupDate, setIndexForPickupDate] = useState("");
    const [order_date ,setorder_date] = useState("");
    const [order_time ,setorder_time] = useState("");
    // Form Submission Function with Validation
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!pickup_order_date) {
            setError("Please select order date");
            return;
        }
        if (!pickup_order_time) {
            setError("Please select order time");
            return;
        }
        if (!fullName) {
            setError("Please enter your name");
            return;
        }
        if (!phone) {
            setError("Please enter your phone number");
            return;
        }

        if (!validateEmail(customerEmail)) {
            setError("Please enter a valid email");
            return;
        }


        // Data to Store in Session Storage
        const formData = {
            pickup_order_date,
            pickup_order_time,
            fullName,
            phone,
            customerEmail,
            order_date,
            order_time,
            type : "pickup",
            PreTime : "25"
        };

        // console.log("ccc" ,formData)

        // Store data in sessionStorage
        sessionStorage.setItem("pickupInfo", JSON.stringify(formData));
        sessionStorage.removeItem("deliveryInfo");
        setError("");
        closePickup(); // close modal if needed
    };

    useEffect(() => {
        if (pickupInfo !== null) {
            setCustomerEmail(pickupInfo?.customerEmail || "");
            setpickup_order_date(pickupInfo?.pickup_order_date || "");
            setpickup_order_time(pickupInfo?.pickup_order_time || "");
            setFullName(pickupInfo?.fullName || "");
            setPhone(pickupInfo?.phone || "");
        }
    }, [pickupInfo !== null])

    const Star = <span style={{ color: "red" }}>*</span>


    useEffect(() => {
        setPickupDays([]);
        setPickupTimeList([]);
        let item = {
            type: "pickup",
            branch_id: sessionBranchID,
            language: "EN",
        };
        api
            .post(`/client/order/branchtimefilter`, item)
            .then((res) => {
                // setbranchTimePickup(res.data.data);
                setPickupDays(res.data.data?.find(item => Array.isArray(item.dayList)) ? res.data.data?.find(item => Array.isArray(item.dayList)) : []);
                const dayList = res?.data?.data?.find(item => Array.isArray(item.timeList)) ? res?.data?.data?.find(item => Array.isArray(item.timeList)) : [];
                const timeList = dayList.timeList;
                // console.log('daylist' ,res.data.data?.find(item => Array.isArray(item.dayList))) 
                setPickupTimeList(timeList ? timeList : []);
            })
            .catch((err) => {
                if (err.response) {
                    console.log(err.response?.data?.error?.message);
                }
            });
    }, [sessionBranchID !== null]);

    return (
        <Modal centered dialogClassName="modal-method" start show={showPickup} onHide={closePickup}>
            <Form >
                <Modal.Body className='mx-1'>
                    <Row className='mt-2 mb-4'>
                        <Col lg={12} md={12} xs={12} sm={12}>
                            <h4 className='title'>Add Pickup Information</h4>
                        </Col>
                    </Row>

                    <Row>

                        <Col lg={6} md={6} xs={12}>
                            <Form.Label className='delivery_label'>Pickup Date {Star}</Form.Label>
                            <Select
                                className='mb-3'
                                isSearchable={false}
                                placeholder="Select Date"
                                options={
                                    PickupDays?.dayList &&
                                    PickupDays?.dayList?.map((day) => ({
                                        label: day.label,
                                        value: day.label,
                                        id: day.id
                                    }))
                                }
                                value={pickup_order_date}
                                onChange={(opt) => {
                                    const index = PickupDays?.dayList?.findIndex(day => day.label === opt.label);

                                    if (opt.value.includes("TODAY")) {
                                        setorder_date(moment().format());
                                    } else if (opt.value.includes("TOMMORROW")) {
                                        const tomorrowDate = moment().add(1, 'day');
                                        setorder_date(tomorrowDate.format());
                                    } else {
                                        setorder_date(moment(opt).format());
                                    }
                                    setIndexForPickupDate(index);
                                    setpickup_order_date(opt);
                                    
                                }}
                                styles={customSelectStyles()}
                            />
                        </Col>
                        <Col lg={6} md={6} xs={12}>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className='delivery_label'>Pickup Time {Star}</Form.Label>
                                <Select
                                    isSearchable={false}
                                    placeholder="Select Time"
                                    styles={customSelectStyles()}
                                    options={
                                        PickupTimeList[IndexForPickupDate] &&
                                        PickupTimeList[IndexForPickupDate]?.map((time) => ({
                                            label: time,
                                            value: time,
                                        }))
                                    }
                                    value={pickup_order_time}
                                    onChange={(opt) => {
                                        setpickup_order_time(opt);
                                        setorder_time(opt.value)
                                    }}
                                />
                            </Form.Group></Col>


                        <Col lg={12} md={12} xs={12} sm={12}>
                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label className='delivery_label'>Full Name {Star}</Form.Label>
                                <Form.Control
                                    id="Enter your full name"
                                    type={"text"}
                                    placeholder="Enter your full name"
                                    required
                                    className='input_model'
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>


                        <Col lg={6} md={6} xs={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className='delivery_label'>Phone {Star}</Form.Label>
                                <div className="position-relative">

                                    <span
                                        className="position-absolute translate-middle top-50 start-0 ms-4"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <CallRegular className='icon_' />
                                    </span>
                                    <Form.Control
                                        id="Phone number"
                                        type={"number"}
                                        placeholder="Enter your Phone number"
                                        required
                                        style={{ paddingLeft: '45px' }}
                                        className='input_model'
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />

                                </div>
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={6} xs={12}>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className='delivery_label'>Email {Star}</Form.Label>
                                <div className="position-relative">
                                    <Form.Control
                                        id="Enter your email"
                                        type={"email"}
                                        placeholder="Enter your email"
                                        required
                                        style={{ paddingLeft: '45px' }}
                                        className='input_model'
                                        value={customerEmail}
                                        onChange={(e) => setCustomerEmail(e.target.value)}
                                    />
                                    <span
                                        className="position-absolute translate-middle start-0 ms-4"
                                        style={{ cursor: 'pointer', top: 22 }}
                                    >
                                        <MailRegular className='icon_' />
                                    </span>
                                </div>
                            </Form.Group>
                        </Col>

                    </Row>
                    <Errormessage error={error} />
                    <Row className='mt-3'>
                        <Col lg={6} md={6} xs={6}>
                            <Button type="button" className='Cancle_button' variant="outlined"
                                onClick={(e) => { 
                                    closePickup() 
                                    setError("");
                                    if(pickupInfo === null){
                                    setCustomerEmail("");
                                    setFullName("");
                                    setIndexForPickupDate("");
                                    setPhone("");
                                    setorder_date("");
                                    setorder_time("");
                                    setpickup_order_date("");
                                    setpickup_order_time("");
                                    }
                                    }}
                                    >
                                Cancel
                            </Button >
                        </Col>
                        <Col lg={6} md={6} xs={6}>
                            <Button onClick={(e) => handleSubmit(e)} type="submit"
                                className='Aplly_button'
                                variant="contained">
                                Add
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Form>
        </Modal>
    );
}

export default PickupDetailsModel;

