import api from "../../../CommonApi/axios";

// cartActions.js
export const incrementQuantity = (event, item_id, quantity_, cart_id, getCartList) => {
      event.preventDefault();
    
      const item = {
        item_id: item_id,
        quantity: Number(quantity_ + 1),
      };
    
      if (cart_id) {
        api
          .patch(`/client/cart/item/${cart_id}`, item)
          .then((res) => {
            getCartList();
            // setpromocode_discount(res.data.data);
          })
          .catch((err) => {
            if (err.response) {
              // Handle error if needed
            }
          });
      }
    };
    
    export const decrementQuantity = (event, item_id, quantity_, cart_id, getCartList) => {
      event.preventDefault();
    
      if (quantity_ === 1) {
        const item = {
          item_id: item_id,
        };
    
        api
          .delete(`/client/cart/item/${cart_id}`, { data: item })
          .then((res) => {
            getCartList();
          })
          .catch((err) => {
            if (err.response) {
              // Handle error if needed
            }
          });
      } else {
        const item = {
          item_id: item_id,
          quantity: Number(quantity_ - 1),
        };
    
        if (cart_id) {
          api
            .patch(`/client/cart/item/${cart_id}`, item)
            .then((res) => {
              getCartList();
              // Uncomment the line below if needed
              // setpromocode_discount(res.data.data);
            })
            .catch((err) => {
              if (err.response) {
                // Handle error if needed
              }
            });
        }
      }
    };

    export const hexToRGBA = (hex, alpha = 0.2) => {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
      };
    